import axios from "axios";
import Cookies from 'js-cookie';

// without token
const LandingInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

LandingInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

LandingInstance.interceptors.response.use(
  (res) => {
     return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);


// without token
const AdminInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `${window.sessionStorage.getItem("admin-tk")}`,
  },
});

AdminInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

AdminInstance.interceptors.response.use(
  (res) => {
    if(res && res.data && res.data.message === 'Expired or Invalid Token'){
        window.location.replace('/');
    }
     return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

let authToken = window.sessionStorage.getItem('access_token')?window.sessionStorage.getItem('access_token'):'';
const CookiesInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: authToken,
  },
});

CookiesInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

CookiesInstance.interceptors.response.use(
  (res) => {
     return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

// set hash key

// without token
const HashKeyInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Hash-key": "2ad8bf6e38aef03f234e3eb4483895408c8820a4f375507fe10d9b199c863bcf",
  },
});

HashKeyInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

HashKeyInstance.interceptors.response.use(
  (res) => {
     return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Bearer token
const BearerInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${window.sessionStorage.getItem("access_token")}`,
  },
});

BearerInstance.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    return newConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

BearerInstance.interceptors.response.use(
  (res) => {
     return Promise.resolve(res);
  },
  (error) => {
    return Promise.reject(error);
  }
);


export {LandingInstance, AdminInstance, CookiesInstance, HashKeyInstance,BearerInstance }

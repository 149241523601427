export function setLocalStorage(key, value) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key) {
  return localStorage.getItem(key);
}

export function getAndSetClicks() {
  const clickCounts = getLocalStorage('clicks');
  if (clickCounts === null) {
    const updatedClicks = 0 + 1;
    setLocalStorage('clicks', updatedClicks);
  } else if (Number(clickCounts) >= 10) {
    setLocalStorage('clicks', 0);
  } else {
    const updatedClicks = Number(clickCounts) + 1;
    setLocalStorage('clicks', updatedClicks);
  }
}

import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    const PSIOdefinition = () => {
        const botMessage = createChatBotMessage('PreScreening.io is SaaS enterprise platform designed to highlight third party AML and other business risks via quick scan to deliver best-in-class Turn around time and case outcomes. ');
    
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      };
    
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            PSIOdefinition 
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
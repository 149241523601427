import React, { PureComponent } from "react";
import CommonHeader from "../common/landing/Header/common-header";
import CommonFooter from "../common/landing/Footer/common-footer";
import { Link} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MailChimpForm from "./MailChimp";
import MainCarousel from "../common/CardCarousel/MainCarousel";


class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      formData: {},
      sender_name: "",
      focus: false,
      demo:false
    };
    this.handleChange = this.handleChange.bind(this);
    AOS.init({
      once: true
    });
  }


  refreshPage() {
    window.location.reload(false);
  }


  handleChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
   
 
  
  render() {
    return (
      <>
        <div className="home-page-conatiner landing-page-main-class">
          <div className="landing-page-conatiner">
            <section className="section-1 purple-background" >
              <div className="container container-xl-ps pt-50 pb-50" >
                <div className="row">
                  <div className="col-lg-7 col-sm-12 pt-110 text-sm1-center ">
                    <div className="max-width " >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="Early-Access-Release font-fff" data-aos="fade-up" data-aos-duration="1250">
                    DueDiliger
                    </h1>
                    <p className="font-fff section-1-title mt-2 mb-4" data-aos="fade-up" data-aos-duration="1250">
                    Global research based due diligence to determine potential red flags
                    </p>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free-2 my-3 mr-4"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call 
                    </button>
                    {/* <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button> */}
                    </div>
                    </div>
                  <div className="col-lg-5 col-sm-12 img-div  d-flex align-tems-center ">
                    {/* <img className="screenviewimg"
                      src={require("../../assets/Landing page icons/PrescreeningDashboard.png")}
                    />
                      <img className="relativeimg"
                      src={require("../../assets/Landing page icons/BEARD10.png")}
                    />
                    <img className="relativeimg2"
                      src={require("../../assets/Landing page icons/BEARD07.png")}
                    /> */}
                  <object data={require("../../assets/Landing page icons/DD Animated vector.svg")}></object>
                  </div>
                </div>
              </div>
            </section>


            <section className="section-2 " >
              <div className="container lp-main-container pt-80 pb-80"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose">Feature</h1>
                  </div>
                </div>
                <div className="row pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                      <img
                        src={require("../../assets/Landing page icons/06-02.svg")}
                      ></img>
                      <div className="purpose-heading font-size-14">
                      Extensive Coverage 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Access to 2 million PEPs, 2,000+ watchlists , 7 Billion+ articles, 17,000+ AML penalties events 

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/03-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Tailored to use-case 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Multiple variants and types of reports are available to cater the needs such as quick, affordable or detailed and extensive 

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/05-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Combines human intelligence with technology 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Report leverage proprietary risk solutions platform, global sources, news channels and databases which are powered by AI and ML tools

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/02-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Deep search/research expertise 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Reports are prepared by experts through deep research on the public sources

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/04-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">
                      Global Coverage 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Coverage across 250 Countries & Jurisdictions, with focus on federal and region level lists for country specific requirement
.
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/01-02.svg")}
                      />
                      <div className="purpose-heading font-size-14">               
                        Supported with analytics 
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Reports are supported with user friendly data visualization and analytics

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="section-2 " >
              <div className="container lp-main-container"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50 pb-50">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-sm1-center">
                    <div className="feature-main">
      
                     <div className="demo-card purple-background p-5">
                      <div className="row">
                        <div className=" col-lg-8 col-sm-8 mb-md-0 col-wd-2">
                        <h1 className="Early-Access-Release font-fff my-5">
                        Interested? Book a Free Demo!
                    </h1>
                        </div>
                        <div className="col-lg-4 col-sm-4  mb-md-0 col-wd-2">
                        <Link
                      to={{pathname:'/contact-us',
                      state:{
                       Subject:"Prescreening.io - Request Demo"
                      }}}
                      type="button"
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free custom-btn my-5 fs-21px"
                    >
                     Book a Free Demo 
                    </Link>
                        </div>

                      </div>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            {/* <section className="section-2 ">
              <div className="container container-xl-ps pt-25 pb-25" data-aos="fade-up" data-aos-duration="1250"> 
              <MainCarousel/>
               </div>
            </section> */}
            <section className="section-2 section-grey">
            <div className="container container-xl-aml"  data-aos="fade-up" data-aos-duration="1000">
            <div className="row row-mt pt-50">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose">
                    DueDiliger Variants
                    </h1>
                  </div>
                </div>
              <div className="row ">
              <div className="col-lg-3 col-md-6 mb-4 mb-md-0 col-wd-2 text-center h-100 my-2">
          <div className="card home-insight-card ">
            <div className="card-body ">
            <div className="home-in"  >
            {/* <span className="purpleBadge2">NEW</span> */}
            
              <img className="card-img-top" src={require("../../assets/04-01.png")} alt="Card image" />
              <div className="card-text font-size-16">PreScreening Report</div>
              <p className="card-pera font-size-12">
              Quick technology driven review of the associated risk with the target entity
              </p>
              </div>
            </div>
          </div>
        </div> 
        <div className="col-lg-3 col-md-6 mb-4 mb-md-0 col-wd-2 text-center h-100  my-2">
          <div className="card home-insight-card" >
            <div className="card-body">
            <div className="home-in"  >
            {/* <span className="purpleBadge2">NEW</span> */}
            
              <img className="card-img-top" src={require("../../assets/01-01.png")} alt="Card image" />
              <div className="card-text font-size-16">Background Check Report</div>
              <p className="card-pera font-size-12">
              Comprehensive report on the risk associated to the target entity and related parties
              </p>
              </div>
            </div>
          </div>
        </div> 
        <div className="col-lg-3 col-md-6 mb-4 mb-md-0 col-wd-2 text-center h-100  my-2">
          <div className="card home-insight-card">
            <div className="card-body">
            <div className="home-in"  >
            {/* <span className="purpleBadge2">NEW</span> */}
            
              <img className="card-img-top" src={require("../../assets/03-01.png")} alt="Card image" />
              <div className="card-text font-size-16">360 Degree Diagnostic Risk Report</div>
              <p className="card-pera font-size-12">
              Global objective check driven approach to determine any potential or existing red flags
              </p>
              </div>
            </div>
          </div>
        </div> 
        <div className="col-lg-3 col-md-6 mb-4 mb-md-0 col-wd-2 text-center h-100  my-2">
          <div className="card home-insight-card">
            <div className="card-body">
            <div className="home-in"  >
            {/* <span className="purpleBadge2">NEW</span> */}
            
              <img className="card-img-top" src={require("../../assets/02-01-01.png")} alt="Card image" />
              <div className="card-text font-size-16">Source of Wealth Report</div>
              <p className="card-pera font-size-12">
              Research-based exercise to determine an individual’s source of wealth status</p>
              </div>
            </div>
          </div>
        </div> 
              </div> 
            </div>            
            </section>
            <section className="section-2 " >
              <div className="container lp-main-container"  data-aos="fade-up" data-aos-duration="1250">
                <div className="row row-mt pt-50">
                  <div className="purpose w-100">
                    <h1 className="Made-For-a-Purpose">
                    Coverage
                    </h1>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/01-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Identity Verification
                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Information about a company registration or individual identity is checked against the public sources
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/02-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Associations

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Link associated companies, key management personnel, beneficial owner, family member and close associate etc via visual association map
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/03-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Global Compliance

                      </div>
                      <div className="purpose-grey-text  font-size-12">
                      Subject is checked against publicly available national and global Politically Exposed Persons (PEPs) List, Sanctions List and Watchlists

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-mt pt-50 pb-80">
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/04-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Adverse Media Coverage

                      </div>
                      <div className=" purpose-grey-text font-size-12">
                      Checks across media and news domains globally and in specific jurisdictions or interest for any negative news

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/05-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Regulatory Checks

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Subject is checked on national and global regulatory bodies to identify any regulatory action

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 mb-4 mb-md-0 col-wd-2 text-center">
                    <div className="purpose-sm">
                    <img
                        src={require("../../assets/Landing page icons/06-02.svg")}
                      />
                      <div className="purpose-heading  font-size-14">
                      Litigation Review

                      </div>
                      <div className="purpose-grey-text font-size-12">
                      Subject is checked on public and government domains for any legal actions or lawsuits
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-2 text-justify purple-background">
            <div className="container container-xl-ps" data-aos="fade-up" data-aos-duration="1250">
                <div className="row">
                  <div className="col-lg-6 col-sm-12 img-div text-center d-flex align-tems-center pt-50">
                  <object data={require("../../assets/Landing page icons/DD Animated Vector 2.svg")}></object>
                  </div>
                  <div className="col-lg-6 col-sm-12 pt-50 ">
                    <div className="max-width px-3" >
                    {/*<p className="font-color-grey font-size-17 font-weight-500 title-heading">ANTI-MONEY LAUNDERING</p> */}
                    <h1 className="fontrandom text-left" >
                       Still have question?<br/><span> Connect </span>with us
                    </h1>
                    <div className="my-3">
                      <div className="d-flex listGroup mb-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div className=" text-left">
                          <h5 className="listItem">Explore our Technology and Data Asset Solutions</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup my-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Get Pricing Information</h5>
                        </div>
                      </div>
                      <div className="d-flex listGroup mt-4">
                        <figure className="mr-4 listImage">
                          <img src={require("../../assets/Dark Blue Arrow.png")}/>
                        </figure>
                        <div  className=" text-left">
                          <h5 className="listItem">Free Trial or Demo</h5>
                        </div>
                      </div>
                    </div>
                    {/* <div className="early-h font-size-22 font-weight-bold">
                      Early Access
                    </div> */}
                     <button
                      className="btn btn-primary sm-primary sm-primary-1 trialButton my-3 mr-2"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Discovery Call 
                    </button>
                    {/* <button
                      className="btn btn-primary sm-primary sm-primary-1 Sign-up-for-Free my-3"
                      onClick={()=>{this.props.props.openBot(true)}}
                    >
                     Book a Free Demo 
                    </button> */}
                    </div>
                    </div>
                </div>
              </div>
            </section>

{/*  

            <section className="section-2" id="Navcontact">
              <div className="container lp-main-container">
                <div className="row row-mt pt-80 pb-80">
                  <div className="col-lg-12 col-md-12 mb-4 mb-md-0 col-wd-2 text-center">
                    <h1 className="Made-For-a-Purpose">Contact Us</h1>
                  </div>
                </div>
                <div className="row row-mt pt-50">
                  <Contact demo={this.state.demo} pageName="contact-us" />
                </div>
              </div>
            </section> */}
                <section className="p-0">
              <div className="section-grey" data-aos="fade-up" data-aos-duration="2000" data-aos-once="true" data-aos-offset="300">
                <div className="container container-xl-ps">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <h2 className="WeeklyNewsletter">Subscribe to our weekly newsletter</h2>
                 </div>
                 <div className="col-md-7 col-sm-12">
                   <MailChimpForm/>
                 </div>
                 </div>
              </div>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default LandingPage;

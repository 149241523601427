import { DASHBOARD, EVENTS, COUNTRY, COMING_SOON, RESOURCES, USER_PROFILE, ADMIN, ANALYTICS } from './url';
//  import images from './images';
// import { BulletList } from 'react-content-loader';

import dashboardIcon from '../assets/images/icons/dashboard.svg';
import eventsIcon from '../assets/images/icons/events.svg';
// import infoIcon from '../assets/images/icons/information.svg';
import countryIcon from '../assets/images/icons/country.svg';
// import notificationIcon from '../assets/images/icons/notification.svg';
import personIcon from '../assets/images/icons/person.svg';
import searchIcon from '../assets/images/icons/search.svg';
import systemIcon from '../assets/images/icons/system.svg';
import adminIcon from '../assets/images/icons/admin.svg';
import analyticsIcon from '../assets/images/icons/analytics.svg';


export const SECURITY_MESSAGE = 'Right click has been disabled for security reasons.';

export const APP_BASE_URL = window.location.origin;

export const amlDescription = `<p>The world today is acutely aware of the social, economic, political and human cost of money laundering. The impact of this phenomenon is found in several negative &amp; nefarious activities, some of them being – drugs, human trafficking, corruption, frauds and terrorism. As per UNODC, The estimated amount of money laundered globally in one year is 2 - 5% of global GDP, or US$800 billion - US$2 trillion.</p>
<p>Today, there are a multitude of global initiatives to combat this menace, led by government agencies, authorities, multilateral bodies, security forces, financial institutions and most importantly, by risk &amp; compliance professionals. AML ZONE has been developed for these individuals, organizations and associations which often form the vanguard of global Anti-Money Laundering efforts.</p>
<p> AML ZONE is designed to be the most comprehensive, analytics based, and data led resource covering money laundering events and cases along with details of the involved individuals, organizations, financial institutions, regulators, methodologies and penalties – from across the world and since the year 2000. Our vision is to empower the Risk, Compliance, Security and Anti-Financial Crimes community with a singular resource on Anti-Money Laundering, to super charge their initiatives in this space and giving them a sustainable information edge in this conflict.</p>`;

const LEFT_SIDE_BLOCK = [
  {
    name: 'Home',
    key: 'dashboard',
    url: DASHBOARD,
    // icon: 'icon-home menu-icon',
    // iconSource: 'image'
    // iconSource: 'font-awesome',
    icon: dashboardIcon,
    iconSource: 'image',
  },
  {
    name: 'Events',
    url: EVENTS,
    // icon: 'mdi mdi-details  menu-icon',
    // iconSource: 'image',
    icon: eventsIcon,
    iconSource: 'image'
  },
  {
    name: 'Country',
    url: COUNTRY,
    // icon: 'icon-globe menu-icon',
    // iconSource: 'font-awesome',
    icon: countryIcon,
    iconSource: 'image'
  },
  // {
  //   name: 'Global Search',
  //   url: '/search',
  //   // icon: 'mdi mdi-search-web menu-icon',
  //   // iconSource: 'font-awesome',
  //   icon: searchIcon,
  //   iconSource: 'image'
  // },
  {
    name: 'Global Search',
    url: '/global-search',
    // icon: 'mdi mdi-search-web menu-icon',
    // iconSource: 'font-awesome',
    icon: searchIcon,
    iconSource: 'image'
  },
  {
    name: 'Admin',
    url: ADMIN,
    // icon: 'mdi mdi-account-settings menu-icon',
    // iconSource: 'font-awesome',
    icon: adminIcon,
    iconSource: 'image',
    className: 'white',

  },
  {
    name: 'Insights',
    url: ANALYTICS,
    icon: analyticsIcon,
    iconSource: 'image',
    className: 'white',

  },
  {
    name: 'Resources',
    url: RESOURCES,
    icon: ' mdi mdi-animation  menu-icon',
    // iconSource: 'image',
  },
  // {
  //   name: 'About',
  //   url: ABOUT,
  //   icon: 'mdi mdi-alert-circle  menu-icon',
  //   iconSource: 'font-awesome',
  // },


  {
    name: 'Profile',
    url: "/profile",
    // icon: 'mdi mdi-account-outline  menu-icon',
    className: 'mt-auto',
    // iconSource: 'font-awesome',
    icon: personIcon,
    iconSource: 'image',
  },
  {
    name: 'Logout',
    url: "/",
    icon: 'mdi mdi-logout  menu-icon',
    // iconSource: 'font-awesome',
  },
];

export const LOGIN_LEFT_SIDE_BLOCK = {
  name: 'Login',
  url: '#',
  icon: 'fa fa-user',
  iconSource: 'font-awesome',
};

export const PROFILE_LEFT_SIDE_BLOCK = {
  name: 'Profile',
  url: USER_PROFILE,
  icon: 'fa fa-user',
  iconSource: 'font-awesome',
};
export const EVENTS_HEADING = 'Events';
export const PENALTIES = 'Penalties';
export const IMPRISONMENT = 'Imprisonment';
export const ENTITY = 'Entity';
export const INDIVIDUAL = 'Individuals';
export const ACTIONS = 'Actions';
export const REGULATING_AGENCIES = 'Regulating Agencies';
export const ENTITIES = 'Organization';
export const GRAPHS = 'Graphs';

export const PIE_CHART_BORDER = [
  'rgba(255,99,132,1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)',
];

export const PIE_CHART_BACKGROUND = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
];

export const LINE_GRAPH_BACKGROUND = '#4599cd87';
export const PRIMARY_COLOR = '#4599cd87';
export const CARD_BACKGROUND = '#807df8';
export const LOADER_PRIMARY_COLOR = '#f3f3f3';
export const LOADER_SECONDARY_COLOR = '#ecebeb';
export const WHITE = '#ffffff';
export const POINT_HOVER = 'rgba(220,220,220,1)';
export const TIMELINE_FILTER = [
  { eventKey: '', name: 'Till Date' },
  { eventKey: 'ytd', name: 'YTD' },
  { eventKey: 'mtd', name: 'MTD' },
  { eventKey: 'qtd', name: 'QTD' },
  { eventKey: 'wtd', name: 'WTD' },
];
export const AREA_OPTION = {
  plugins: {
    filler: {
      propagate: true,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: true,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
        },
        scaleLabel: {
          display: true,
          labelString: 'Y text',
        },
      },
    ],
  },
};

// export const REASON_PENALTY_LEGEND = {
//   Actions: { action: 'Reasoning and Amount of Penalties (in Mn USD)', key: 'Reasoning and Events' },
//   Penalties: { action: 'Amount of penalities', key: 'Reason' },
// };

// export const INDUSTRY_LEGEND = {
//   Actions: { action: 'Industry and Amount of Penalties (in Mn USD)', key: 'Industry' },
//   Penalties: { action: 'Industry and Events', key: 'Number of action taken' },
// };


export const REASON_PENALTY_LEGEND = {
  Actions: { action: 'Reasoning and Events', key: 'Reason' },
  Penalties: { action: 'Reasoning and Amount of Penalties (in Mn USD)', key: 'Reason' },
};

export const INDUSTRY_LEGEND = {
  Actions: { action: 'Industry and Events', key: 'Industry' },
  Penalties: { action: 'Industry and Amount of Penalties (in Mn USD)', key: 'Number of action taken' },
};

export const PENALTY_YEAR_LEGEND = {
  Events: { action: 'Year', key: 'Events' },
  Penalties: { action: 'Year', key: 'Amount of Penalties (in Mn USD)' },
};
export const COUNTRY_DETAILS_TABS = [
  { eventKey: 'Country Profile', name: 'Country Profile' },
  { eventKey: 'Events', name: 'Events' },
  { eventKey: 'Global Indices', name: 'Global Indices' },
  { eventKey: 'Economic Data', name: 'Economic Data' },
  { eventKey: 'Regulatory', name: 'Regulatory' },
  { eventKey: 'Graphs', name: 'Graphs' },
];

export const COUNTRY_PROFILE = 'Country Profile';
export const GLOBAL_INDICES = 'Global Indices';
export const ECONOMIC_DATA = 'Economic Data';
export const REGULATORY = 'Regulatory';

export const PROFILE = [
  { name: 'Name', key: 'country_name' },
  { name: 'Official Name', key: 'official_name' },
  { name: 'Capital', key: 'capital' },
  { name: 'Region', key: 'region' },
  { name: 'Country Code', key: 'country_code' },
  { name: 'Development Status', key: 'development_status' },
  { name: 'Currency', key: 'currency' },
];
export const MEMBERSHIP = [
  { name: 'UN Membership', key: 'un_member' },
  { name: 'FATF Membership', key: 'fatf_member' },
  { name: 'WTO Membership', key: 'wto_member' },
  { name: 'OECD Membership', key: 'oecd_member' },
  { name: 'World Bank Membership', key: 'world_bank_member' },
];
export const SANCTIONS = [
  { name: 'EU Sanctions', key: 'eu_sanctions' },
  { name: 'UN Sanctions', key: 'un_sanctions' },
];

export const COUNTRY_INDICE = [
  {
    name: 'BASEL AML Index',
    link: 'https://www.baselgovernance.org/basel-aml-index',
    key: 'base_aml_index',
    info:
      'The Basel AML Index is an annual ranking assessing country risk regarding money laundering/terrorism financing. It focuses on anti-money laundering and counter terrorist financing (AML/CTF) frameworks and other related factors such as financial/public transparency and judicial strength.',
  },
  {
    name: 'Corruption Perception Index',
    link: 'https://www.transparency.org/research/cpi/overview',
    key: 'corruption_index',
    info:
      'The Corruption Perceptions Index (CPI) is an index published annually by Transparency International since 1995 which ranks countries "by their perceived levels of public sector corruption, as determined by expert assessments and opinion surveys." The CPI generally defines corruption as "the misuse of public power for private benefit".',
  },
  {
    name: 'Global Terrorism Index',
    link: 'http://visionofhumanity.org/indexes/terrorism-index/',
    key: 'terrorisim_index',
    info:
      'The Global Terrorism Index (GTI) is a report published annually by the Institute for Economics and Peace (IEP). The index provides a comprehensive summary of the key global trends and patterns in terrorism since 2000. It produces a composite score in order to provide an ordinal ranking of countries on the impact of terrorism.',
  },
  {
    name: 'Global Peace Index',
    link: 'http://visionofhumanity.org/indexes/global-peace-index/',
    key: 'peace_index',
    info:
      'Global Peace Index (GPI) measures the relative position of nations and regions peacefulness. The GPI ranks 163 independent states and territories (99.7 per cent of the world’s population) according to their levels of peacefulness. In the past decade, the GPI has presented trends of increased global violence and less peacefulness.',
  },
  {
    name: 'Fragile States Index',
    link: 'https://fragilestatesindex.org',
    key: 'fragile_state_index',
    info:
      'The Fragile States Index (FSI; formerly the Failed States Index) is an annual report published by the United States think tank the Fund for Peace and the American magazine Foreign Policy since 2005. The list aims to assess states vulnerability to conflict or collapse, ranking all sovereign states with membership in the United Nations where there is enough data available for analysis.',
  },
  {
    name: 'Cyber Security Index',
    link: 'https://www.itu.int/en/ITU-D/Cybersecurity/Pages/global-cybersecurity-index.aspx',
    key: 'cyber_index',
    info:
      'The Global Cyber security Index (GCI) is a trusted reference that measures the commitment of countries to cyber security at a global level – to raise awareness of the importance and different dimensions of the issue. As cybersecurity has a broad field of application, cutting across many industries and various sectors.',
  },
];

export const COUNTRTY_ECONOMIC_KEY = [
  {
    name: 'GDP (Nominal) (Billion USD)',
    key: 'gdp_nominal',
  },
  { name: 'GDP (Nominal) Growth Rate (%)', key: 'gdp_growth_rate' },
  { name: 'GDP (PPP) (Billion USD)', key: 'gdp_ppp' },
  { name: 'Inflation Rate (%)', key: 'inflation_rate' },
  { name: 'Remittance Inflow (Billion USD)', key: 'remittance_inflow' },
  { name: 'Remittance Outflow (Billion USD)', key: 'remittance_outflow' },
];

export const COUNTRY_ECONOMIC_TABLE_HEADER = ['', 2016, 2017, 2018, 2019, 'Graph'];

export const SOCIAL_SHARE = [
  { link: window.location.href, icon: 'fa fa-facebook' },
  { link: window.location.href, icon: 'fa fa-twitter' },
  { link: window.location.href, icon: 'fa fa-linkedin' },
  { link: window.location.href, icon: 'fa fa-envelope-o' },
];

export const SEARCH_TABLE_All = ['Id', 'Name', 'Country', 'Method', 'Date'];
export const SEARCH_TABLE_EVENTS = ['Id', 'Name', 'Country', 'Method', 'Date'];
export const SEARCH_TABLE_ENTITY = ['Id', 'Name', 'Country', 'Sector', 'Industry'];
export const SEARCH_TABLE_INDIVIDUAL = ['Id', 'Name', 'Gender', 'Nationality', 'Industry'];
export const SEARCH_TABLE_REGULATOR = ['Id', 'Name', 'Country', 'Area', 'Jurisdiction'];

const KEYMAP_EVENTS = {
  uid: 'event_uid',
  name: 'event_name',
  country: 'event_country',
  method: 'method',
  date: 'event_date',
};

const KEYMAP_ENTITY = {
  uid: 'entity_uid',
  name: 'entity_name',
  country: 'entity_country',
  method: 'entity_sector',
  date: 'entity_industry',
};
const KEYMAP_INDIVIDUAL = {
  uid: 'individual_uid',
  name: 'individual_name',
  country: 'individual_gender',
  method: 'individual_nationality',
  date: 'individual_industry',
};

const KEYMAP_REGULATOR = {
  uid: 'regulator_uid',
  name: 'regulator_name',
  country: 'regulator_country',
  method: 'regulator_area',
  date: 'regulator_judiriction',
};
const KEYMAP_ALL = {
  uid: 'event_uid',
  name: 'event_name',
  country: 'event_country',
  method: 'method',
  date: 'event_date',
};
export const SEARCH_ACCORDION = [
  {
    name: 'Event',
    tableHeader: SEARCH_TABLE_EVENTS,
    key: 'eventSearchData',
    keyMap: KEYMAP_EVENTS,
  },
  {
    name: 'Entity',
    tableHeader: SEARCH_TABLE_ENTITY,
    key: 'entitySearchData',
    keyMap: KEYMAP_ENTITY,
  },
  {
    name: 'Individual',
    tableHeader: SEARCH_TABLE_INDIVIDUAL,
    key: 'individualSearchData',
    keyMap: KEYMAP_INDIVIDUAL,
  },
  {
    name: 'Regulator',
    tableHeader: SEARCH_TABLE_REGULATOR,
    key: 'regulatorSearchData',
    keyMap: KEYMAP_REGULATOR,
  },
  {
    name: 'All',
    tableHeader: SEARCH_TABLE_All,
    key: 'freetextSearchData',
    keyMap: KEYMAP_ALL,
  },
];
export const METHODOLOGY_PILLS = [
  'Scope',
  'Component of Data Asset',
  'Data Dictionary',
  'Research Process',
  'Business Rules',
  'Assumptions',
  'Sources and Types',
];

export const COUNTRY_REGULATOR = ['#', 'Name of Agency', 'Website'];
export const COUNTRY_AML_LAWS = ['Name of Regulation', 'Country', 'Link', 'Document'];

export default LEFT_SIDE_BLOCK;

export const EVENTS_GRAPH = 'Events';
export const PENALTY_GRAPH = 'Penalties';

export const COUNTRY_INDICE_RANK = {
  base_aml_index: {
    high: 'Rank 1 - Highest AML Risk',
    low: 'Rank 149 - Lowest AML Risk',
    rank: { 2019: 125, 2018: 129, 2017: 146, 2016: 149 },
  },
  corruption_index: {
    high: 'Rank 1 - Lowest Perception of Corruption ',
    low: 'Rank 180 - Highest Perception of Corruption',
    rank: { 2019: 180, 2018: 180, 2017: 176, 2016: 176 },
  },
  terrorisim_index: {
    high: 'Rank 1 - Highest Terrorism Impact on country',
    low: 'Rank 163 - Lowest Terrorism Impact on country',
    rank: { 2019: 163, 2018: 163, 2017: 160, 2016: 160 },
  },
  cyber_index: {
    high: ' Rank 1 - Highest Commitment to Cybersecurity',
    low: 'Rank 189 - Lowest Commitment to Cybersecurity',
    rank: { 2019: 189, 2018: 189, 2017: 188, 2016: 188 },
  },
  fragile_state_index: {
    high: ' Rank 1 - Highest Fragility',
    low: ' Rank 178 - Lowest Fragility',
    rank: { 2019: 178, 2018: 178, 2017: 178, 2016: 178 },
  },
  peace_index: {
    high: 'Rank 1 - Highest Peace',
    low: ' Rank 163 - Lowest Peace',
    rank: { 2019: 163, 2018: 163, 2017: 163, 2016: 163 },
  },
};

export const DATE_FORMAT = 'yyyy-MM-dd';
